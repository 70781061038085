/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApplyDiscount {
  /** @maxLength 50 */
  discount_code: string;
  quote_hash: string;
}

export interface AuthCheckEmailVerificationRetrieveParams {
  /** Email address */
  email: string;
}

export interface BlacklistedEmailDomain {
  /**
   * @maxLength 255
   * @pattern (?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]
   */
  domain: string;
}

export enum BlankEnum {
  Value = "",
}

export interface CheckoutResponse {
  session_id: string;
}

export interface CofaceCompanyDataResponse {
  city: string;
  country: string;
  name: string;
  postcode: string;
  reg_id?: string;
  street: string;
  tax_id?: string;
  vat_id: string;
}

export interface CofaceCompanyResponse {
  country: string;
  vat_number: string;
}

export interface Contact {
  /** @maxLength 100 */
  company_name?: string;
  /** Preferred contact method */
  contact_method?: ContactMethodEnum;
  country?: CountryEnum | BlankEnum;
  /**
   * @format email
   * @maxLength 254
   */
  email?: string;
  readonly id: number;
  /** @maxLength 255 */
  name?: string;
  /** @maxLength 128 */
  phone?: string;
}

/**
 * * `email` - Email
 * * `phone` - Phone
 * * `none` - Do Not Contact
 */
export enum ContactMethodEnum {
  Email = "email",
  Phone = "phone",
  None = "none",
}

/**
 * * `AF` - Afghanistan
 * * `AX` - Åland Islands
 * * `AL` - Albania
 * * `DZ` - Algeria
 * * `AS` - American Samoa
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AI` - Anguilla
 * * `AQ` - Antarctica
 * * `AG` - Antigua and Barbuda
 * * `AR` - Argentina
 * * `AM` - Armenia
 * * `AW` - Aruba
 * * `AU` - Australia
 * * `AT` - Austria
 * * `AZ` - Azerbaijan
 * * `BS` - Bahamas
 * * `BH` - Bahrain
 * * `BD` - Bangladesh
 * * `BB` - Barbados
 * * `BY` - Belarus
 * * `BE` - Belgium
 * * `BZ` - Belize
 * * `BJ` - Benin
 * * `BM` - Bermuda
 * * `BT` - Bhutan
 * * `BO` - Bolivia
 * * `BQ` - Bonaire, Sint Eustatius and Saba
 * * `BA` - Bosnia and Herzegovina
 * * `BW` - Botswana
 * * `BV` - Bouvet Island
 * * `BR` - Brazil
 * * `IO` - British Indian Ocean Territory
 * * `BN` - Brunei
 * * `BG` - Bulgaria
 * * `BF` - Burkina Faso
 * * `BI` - Burundi
 * * `CV` - Cabo Verde
 * * `KH` - Cambodia
 * * `CM` - Cameroon
 * * `CA` - Canada
 * * `KY` - Cayman Islands
 * * `CF` - Central African Republic
 * * `TD` - Chad
 * * `CL` - Chile
 * * `CN` - China
 * * `CX` - Christmas Island
 * * `CC` - Cocos (Keeling) Islands
 * * `CO` - Colombia
 * * `KM` - Comoros
 * * `CG` - Congo
 * * `CD` - Congo (the Democratic Republic of the)
 * * `CK` - Cook Islands
 * * `CR` - Costa Rica
 * * `CI` - Côte d'Ivoire
 * * `HR` - Croatia
 * * `CU` - Cuba
 * * `CW` - Curaçao
 * * `CY` - Cyprus
 * * `CZ` - Czechia
 * * `DK` - Denmark
 * * `DJ` - Djibouti
 * * `DM` - Dominica
 * * `DO` - Dominican Republic
 * * `EC` - Ecuador
 * * `EG` - Egypt
 * * `SV` - El Salvador
 * * `GQ` - Equatorial Guinea
 * * `ER` - Eritrea
 * * `EE` - Estonia
 * * `SZ` - Eswatini
 * * `ET` - Ethiopia
 * * `FK` - Falkland Islands (Malvinas)
 * * `FO` - Faroe Islands
 * * `FJ` - Fiji
 * * `FI` - Finland
 * * `FR` - France
 * * `GF` - French Guiana
 * * `PF` - French Polynesia
 * * `TF` - French Southern Territories
 * * `GA` - Gabon
 * * `GM` - Gambia
 * * `GE` - Georgia
 * * `DE` - Germany
 * * `GH` - Ghana
 * * `GI` - Gibraltar
 * * `GR` - Greece
 * * `GL` - Greenland
 * * `GD` - Grenada
 * * `GP` - Guadeloupe
 * * `GU` - Guam
 * * `GT` - Guatemala
 * * `GG` - Guernsey
 * * `GN` - Guinea
 * * `GW` - Guinea-Bissau
 * * `GY` - Guyana
 * * `HT` - Haiti
 * * `HM` - Heard Island and McDonald Islands
 * * `VA` - Holy See
 * * `HN` - Honduras
 * * `HK` - Hong Kong
 * * `HU` - Hungary
 * * `IS` - Iceland
 * * `IN` - India
 * * `ID` - Indonesia
 * * `IR` - Iran
 * * `IQ` - Iraq
 * * `IE` - Ireland
 * * `IM` - Isle of Man
 * * `IL` - Israel
 * * `IT` - Italy
 * * `JM` - Jamaica
 * * `JP` - Japan
 * * `JE` - Jersey
 * * `JO` - Jordan
 * * `KZ` - Kazakhstan
 * * `KE` - Kenya
 * * `KI` - Kiribati
 * * `KW` - Kuwait
 * * `KG` - Kyrgyzstan
 * * `LA` - Laos
 * * `LV` - Latvia
 * * `LB` - Lebanon
 * * `LS` - Lesotho
 * * `LR` - Liberia
 * * `LY` - Libya
 * * `LI` - Liechtenstein
 * * `LT` - Lithuania
 * * `LU` - Luxembourg
 * * `MO` - Macao
 * * `MG` - Madagascar
 * * `MW` - Malawi
 * * `MY` - Malaysia
 * * `MV` - Maldives
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MH` - Marshall Islands
 * * `MQ` - Martinique
 * * `MR` - Mauritania
 * * `MU` - Mauritius
 * * `YT` - Mayotte
 * * `MX` - Mexico
 * * `FM` - Micronesia (Federated States of)
 * * `MD` - Moldova
 * * `MC` - Monaco
 * * `MN` - Mongolia
 * * `ME` - Montenegro
 * * `MS` - Montserrat
 * * `MA` - Morocco
 * * `MZ` - Mozambique
 * * `MM` - Myanmar
 * * `NA` - Namibia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NL` - Netherlands
 * * `NC` - New Caledonia
 * * `NZ` - New Zealand
 * * `NI` - Nicaragua
 * * `NE` - Niger
 * * `NG` - Nigeria
 * * `NU` - Niue
 * * `NF` - Norfolk Island
 * * `KP` - North Korea
 * * `MK` - North Macedonia
 * * `MP` - Northern Mariana Islands
 * * `NO` - Norway
 * * `OM` - Oman
 * * `PK` - Pakistan
 * * `PW` - Palau
 * * `PS` - Palestine, State of
 * * `PA` - Panama
 * * `PG` - Papua New Guinea
 * * `PY` - Paraguay
 * * `PE` - Peru
 * * `PH` - Philippines
 * * `PN` - Pitcairn
 * * `PL` - Poland
 * * `PT` - Portugal
 * * `PR` - Puerto Rico
 * * `QA` - Qatar
 * * `RE` - Réunion
 * * `RO` - Romania
 * * `RU` - Russia
 * * `RW` - Rwanda
 * * `BL` - Saint Barthélemy
 * * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * * `KN` - Saint Kitts and Nevis
 * * `LC` - Saint Lucia
 * * `MF` - Saint Martin (French part)
 * * `PM` - Saint Pierre and Miquelon
 * * `VC` - Saint Vincent and the Grenadines
 * * `WS` - Samoa
 * * `SM` - San Marino
 * * `ST` - Sao Tome and Principe
 * * `SA` - Saudi Arabia
 * * `SN` - Senegal
 * * `RS` - Serbia
 * * `SC` - Seychelles
 * * `SL` - Sierra Leone
 * * `SG` - Singapore
 * * `SX` - Sint Maarten (Dutch part)
 * * `SK` - Slovakia
 * * `SI` - Slovenia
 * * `SB` - Solomon Islands
 * * `SO` - Somalia
 * * `ZA` - South Africa
 * * `GS` - South Georgia and the South Sandwich Islands
 * * `KR` - South Korea
 * * `SS` - South Sudan
 * * `ES` - Spain
 * * `LK` - Sri Lanka
 * * `SD` - Sudan
 * * `SR` - Suriname
 * * `SJ` - Svalbard and Jan Mayen
 * * `SE` - Sweden
 * * `CH` - Switzerland
 * * `SY` - Syria
 * * `TW` - Taiwan
 * * `TJ` - Tajikistan
 * * `TZ` - Tanzania
 * * `TH` - Thailand
 * * `TL` - Timor-Leste
 * * `TG` - Togo
 * * `TK` - Tokelau
 * * `TO` - Tonga
 * * `TT` - Trinidad and Tobago
 * * `TN` - Tunisia
 * * `TR` - Türkiye
 * * `TM` - Turkmenistan
 * * `TC` - Turks and Caicos Islands
 * * `TV` - Tuvalu
 * * `UG` - Uganda
 * * `UA` - Ukraine
 * * `AE` - United Arab Emirates
 * * `GB` - United Kingdom
 * * `UM` - United States Minor Outlying Islands
 * * `US` - United States of America
 * * `UY` - Uruguay
 * * `UZ` - Uzbekistan
 * * `VU` - Vanuatu
 * * `VE` - Venezuela
 * * `VN` - Vietnam
 * * `VG` - Virgin Islands (British)
 * * `VI` - Virgin Islands (U.S.)
 * * `WF` - Wallis and Futuna
 * * `EH` - Western Sahara
 * * `YE` - Yemen
 * * `ZM` - Zambia
 * * `ZW` - Zimbabwe
 */
export enum CountryEnum {
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  CV = "CV",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  SZ = "SZ",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  VA = "VA",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  KP = "KP",
  MK = "MK",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  KR = "KR",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW",
}

export interface DefaultPriceRequest {
  ftl: boolean;
}

export interface DefaultPriceResponse {
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly default_price: string;
  readonly quote_custom: boolean;
}

export interface DefaultPriceSettings {
  /** Show or hide default price */
  show_price?: boolean;
}

export interface Discount {
  /**
   * Discount Amount
   * Specify the amount for flat discount or percentage value (0-100) for percentage discount.
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  amount: string;
  /**
   * Discount Code
   * @maxLength 50
   */
  code: string;
  /**
   * * `flat` - Flat amount
   * * `percent` - Percentage
   */
  discount_type?: DiscountTypeEnum;
  /**
   * Discount Name
   * @maxLength 100
   */
  name: string;
}

/**
 * * `flat` - Flat amount
 * * `percent` - Percentage
 */
export enum DiscountTypeEnum {
  Flat = "flat",
  Percent = "percent",
}

/** Adds nested create feature */
export interface DiscountUsage {
  readonly discount: Discount;
  /** @format date-time */
  readonly used_on: string;
}

/**
 * * `FIXED` - Fixed
 * * `PERCENTAGE` - Percentage
 */
export enum ExtraFeePriceUnitEnum {
  FIXED = "FIXED",
  PERCENTAGE = "PERCENTAGE",
}

export interface ExtraFees {
  /**
   * ADR
   * Transportation of dangerous goods based on UN conditions
   * @format double
   * @min 0
   */
  adr_price?: number;
  /** DAL price unit */
  adr_price_unit?: ExtraFeePriceUnitEnum;
  /**
   * Driver assisted loading
   * Fixed price for service where the driver actively assists in the loading of goods onto the vehicle.
   * @format double
   * @min 0
   */
  dal_price?: number;
  /**
   * * `FIXED` - Fixed
   * * `PERCENTAGE` - Percentage
   */
  dal_price_unit?: ExtraFeePriceUnitEnum;
  /**
   * Driver assisted unloading
   * Fixed price for service where the driver actively assists in the unloading of goods onto the vehicle.
   * @format double
   * @min 0
   */
  dau_price?: number;
  /**
   * * `FIXED` - Fixed
   * * `PERCENTAGE` - Percentage
   */
  dau_price_unit?: ExtraFeePriceUnitEnum;
  /**
   * Extended vehicle loading area
   * Loading area with extended length or height.
   * @format double
   * @min 0
   */
  extended_vehicle_price?: number;
  /**
   * * `FIXED` - Fixed
   * * `PERCENTAGE` - Percentage
   */
  extended_vehicle_price_unit?: ExtraFeePriceUnitEnum;
  /**
   * Frigo
   * Refrigerator truck
   * @format double
   * @min 0
   */
  frigo_price?: number;
  /**
   * * `FIXED` - Fixed
   * * `PERCENTAGE` - Percentage
   */
  frigo_price_unit?: ExtraFeePriceUnitEnum;
  /**
   * Taillift
   * Vehicle with taillift
   * @format double
   * @min 0
   */
  taillift_price?: number;
  /**
   * * `FIXED` - Fixed
   * * `PERCENTAGE` - Percentage
   */
  taillift_price_unit?: ExtraFeePriceUnitEnum;
  /**
   * Fixed price per hour, if there is a necessary downtime of the vehicle due to longer loading or unloading of goods.
   * @format double
   * @min 0
   */
  vehicle_downtime_price?: number;
  /** DAL price unit */
  vehicle_downtime_price_unit?: ExtraFeePriceUnitEnum;
  vehicle_type?: VehicleTypeEnum | BlankEnum;
}

/** Adds nested create feature */
export interface Feedback {
  /** @format date-time */
  readonly created_at: string;
  feedback_text: string;
  readonly id: number;
  /** @format date-time */
  readonly updated_at: string | null;
  user?: User;
  utm_parameters?: Utm;
}

export interface Good {
  /**
   * @min 0
   * @max 2147483647
   */
  count?: number | null;
  /** @format date-time */
  readonly created_at: string;
  /**
   * cm
   * @min 0
   * @max 9999
   */
  height?: number | null;
  readonly id: number;
  /**
   * cm
   * @min 0
   * @max 9999
   */
  length?: number | null;
  /**
   * * `Entire Vehicle` - Entire Vehicle
   * * `Euro Pallet` - Euro Pallet
   * * `Industrial Pallet` - Industrial Pallet
   * * `Euro Gitterbox` - Euro Gitterbox
   * * `IBC Container` - IBC Container
   * * `Custom` - Custom
   */
  package_type?: PackageTypeEnum;
  /** stackable packaging */
  stackable?: boolean;
  /**
   * Weight
   * kg
   * @min 0
   * @max 24000
   */
  unit_weight?: number | null;
  /** @format date-time */
  readonly updated_at: string | null;
  /**
   * kg
   * @min 0
   * @max 24000
   */
  weight_limit?: number | null;
  /**
   * cm
   * @min 0
   * @max 9999
   */
  width?: number | null;
}

/** Serializer for JWT authentication. */
export interface JWT {
  access: string;
  refresh: string;
  /** User model w/o password */
  user: UserDetails;
}

export interface Location {
  country: string;
  /** @format double */
  lat: number;
  /** @format double */
  lng: number;
}

export interface Login {
  /** @format email */
  email?: string;
  password: string;
  username?: string;
}

export enum NullEnum {
  Null = null,
}

/** Adds nested create feature */
export interface Order {
  manager?: User;
  /**
   * @min 0
   * @max 2147483647
   */
  offer_id?: number | null;
  options?: Transport[];
  /** @maxLength 255 */
  order_number?: string | null;
  quote_id: number;
  /**
   * * `PENDING` - pending
   * * `EXPIRED` - expired
   * * `CLOSED` - closed
   * * `PROCESSING` - processing
   * * `DONE` - done
   * * `CANCELLED` - cancelled
   */
  status?: OrderStatusEnum;
  /**
   * * `SEARCHING_FOR_THE_VEHICLE` - Searching for the vehicle
   * * `VEHICLE_FOUND` - Vehicle found
   * * `LOADED` - Loaded
   * * `ON_ROAD` - On Road
   * * `UNLOADED` - Unloaded
   */
  transport_progress?: TransportProgressEnum;
  readonly transports: Transport[];
}

/**
 * * `PENDING` - pending
 * * `EXPIRED` - expired
 * * `CLOSED` - closed
 * * `PROCESSING` - processing
 * * `DONE` - done
 * * `CANCELLED` - cancelled
 */
export enum OrderStatusEnum {
  PENDING = "PENDING",
  EXPIRED = "EXPIRED",
  CLOSED = "CLOSED",
  PROCESSING = "PROCESSING",
  DONE = "DONE",
  CANCELLED = "CANCELLED",
}

/**
 * * `SWIDA` - Swida
 * * `TRANSPORTLY` - Transportly
 */
export enum OriginEnum {
  SWIDA = "SWIDA",
  TRANSPORTLY = "TRANSPORTLY",
}

/**
 * * `Entire Vehicle` - Entire Vehicle
 * * `Euro Pallet` - Euro Pallet
 * * `Industrial Pallet` - Industrial Pallet
 * * `Euro Gitterbox` - Euro Gitterbox
 * * `IBC Container` - IBC Container
 * * `Custom` - Custom
 */
export enum PackageTypeEnum {
  EntireVehicle = "Entire Vehicle",
  EuroPallet = "Euro Pallet",
  IndustrialPallet = "Industrial Pallet",
  EuroGitterbox = "Euro Gitterbox",
  IBCContainer = "IBC Container",
  Custom = "Custom",
}

export interface PasswordChange {
  /** @maxLength 128 */
  new_password1: string;
  /** @maxLength 128 */
  new_password2: string;
  /** @maxLength 128 */
  old_password: string;
}

/** Serializer for requesting a password reset e-mail. */
export interface PasswordReset {
  /** @format email */
  email: string;
}

/** Serializer for confirming a password reset attempt. */
export interface PasswordResetConfirm {
  /** @maxLength 128 */
  new_password1: string;
  /** @maxLength 128 */
  new_password2: string;
  token: string;
  uid: string;
}

/** Adds nested create feature */
export interface PatchedOrder {
  manager?: User;
  /**
   * @min 0
   * @max 2147483647
   */
  offer_id?: number | null;
  options?: Transport[];
  /** @maxLength 255 */
  order_number?: string | null;
  quote_id?: number;
  /**
   * * `PENDING` - pending
   * * `EXPIRED` - expired
   * * `CLOSED` - closed
   * * `PROCESSING` - processing
   * * `DONE` - done
   * * `CANCELLED` - cancelled
   */
  status?: OrderStatusEnum;
  /**
   * * `SEARCHING_FOR_THE_VEHICLE` - Searching for the vehicle
   * * `VEHICLE_FOUND` - Vehicle found
   * * `LOADED` - Loaded
   * * `ON_ROAD` - On Road
   * * `UNLOADED` - Unloaded
   */
  transport_progress?: TransportProgressEnum;
  readonly transports?: Transport[];
}

/** Adds nested create feature */
export interface PatchedQuoteByHash {
  readonly billing_info?: Record<string, any>;
  contact?: number | null;
  /** @format date-time */
  readonly created_at?: string;
  created_by_manager?: boolean;
  default_price_option?: number | null;
  discount_usage?: DiscountUsage[];
  /**
   * km
   * @format decimal
   * @pattern ^-?\d{0,5}(?:\.\d{0,2})?$
   */
  distance?: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  duration?: string | null;
  /** @format date-time */
  expiration_time?: string | null;
  /** Full truck load */
  ftl?: boolean;
  readonly goods?: Good[];
  /**
   * Hash key
   * @maxLength 64
   */
  hash?: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  insurance_limit?: string | null;
  is_magic_link_visited?: boolean;
  /** Operator */
  manager_account?: number | null;
  /** Notes */
  note?: string;
  notify_on_app_launch?: boolean;
  /** @format date-time */
  paid_datetime?: string | null;
  payment_method?: PaymentMethodEnum | BlankEnum;
  polyline?: string | null;
  /** @format date-time */
  price_option_set_time?: string | null;
  readonly price_options?: PriceOption[];
  /** Custom quote */
  quote_custom?: boolean;
  /** Adds nested create feature */
  selected_price_option?: PriceOption;
  special_requirements?: SpecialRequirements;
  /**
   * * `DRAFT` - Draft
   * * `PRICEABLE` - Priceable
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `WITH_ORDER` - With Order
   */
  state?: StateEnum;
  /** @format date-time */
  readonly updated_at?: string | null;
  user?: User;
  utm_parameters?: Utm;
  vehicle_type?: VehicleTypeEnum | BlankEnum;
  waypoints?: Waypoint[];
}

/** Adds nested create feature */
export interface PatchedQuoteByID {
  /** Billing information */
  billing_info?: number | null;
  contact?: Contact;
  /** @format date-time */
  readonly created_at?: string;
  created_by_manager?: boolean;
  default_price_option?: number | null;
  /**
   * km
   * @format decimal
   * @pattern ^-?\d{0,5}(?:\.\d{0,2})?$
   */
  distance?: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  duration?: string | null;
  /** @format date-time */
  expiration_time?: string | null;
  /** Full truck load */
  ftl?: boolean;
  goods?: Good[];
  readonly id?: number;
  is_magic_link_visited?: boolean;
  /** Operator */
  manager_account?: number | null;
  /** Notes */
  note?: string;
  notify_on_app_launch?: boolean;
  polyline?: string | null;
  /** @format date-time */
  price_option_set_time?: string | null;
  /** Custom quote */
  quote_custom?: boolean;
  selected_price_option?: number | null;
  special_requirements?: SpecialRequirements;
  /**
   * * `DRAFT` - Draft
   * * `PRICEABLE` - Priceable
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `WITH_ORDER` - With Order
   */
  state?: StateEnum;
  /** @format date-time */
  readonly updated_at?: string | null;
  user?: User;
  utm_parameters?: Utm;
  /** @format uuid */
  uuid?: string;
  vehicle_type?: VehicleTypeEnum | BlankEnum;
  waypoints?: Waypoint[];
}

export interface PatchedUser {
  /** @maxLength 100 */
  city?: string;
  /** @maxLength 100 */
  company_name?: string;
  country?: CountryEnum | BlankEnum | NullEnum | null;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** User is company? */
  is_company?: boolean;
  /** User is vat payer? */
  is_vat_payer?: boolean;
  /**
   * User name
   * @maxLength 255
   */
  name?: string;
  /** @maxLength 128 */
  phone?: string;
  /**
   * Post code
   * @maxLength 20
   */
  postcode?: string;
  /** @maxLength 100 */
  street?: string;
  /** @maxLength 100 */
  street_number?: string;
  timezone?: string;
  /** @maxLength 150 */
  vat_number?: string;
}

/** User model w/o password */
export interface PatchedUserDetails {
  /** @maxLength 100 */
  city?: string;
  /** @maxLength 100 */
  company_name?: string;
  country?: CountryEnum | BlankEnum;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  readonly id?: number;
  /** User is company? */
  is_company?: boolean;
  readonly is_email_verified?: boolean;
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  readonly is_staff?: boolean;
  /** User is vat payer? */
  is_vat_payer?: boolean;
  /**
   * User name
   * @maxLength 255
   */
  name?: string;
  /** @maxLength 128 */
  phone?: string;
  /**
   * Post code
   * @maxLength 20
   */
  postcode?: string;
  /** @maxLength 100 */
  street?: string;
  /** @maxLength 100 */
  street_number?: string;
  timezone?: string;
  /** @maxLength 150 */
  vat_number?: string;
}

/**
 * * `INVOICE` - invoice
 * * `GATEWAY` - payment gateway
 */
export enum PaymentMethodEnum {
  INVOICE = "INVOICE",
  GATEWAY = "GATEWAY",
}

/** Adds nested create feature */
export interface PriceOption {
  category?: TransportCategory;
  delivery_datetime: Record<string, string>;
  readonly id: number;
  pickup_datetime: Record<string, string>;
  readonly pricing: Pricing;
  /**
   * Pricer ID
   * @min 0
   * @max 2147483647
   */
  result_id?: number | null;
}

export interface Pricing {
  /**
   * €
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  base_price: string;
  /**
   * €
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  cost_price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly final_price: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly final_price_with_vat: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly total_discounts: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly total_special_requirements_cost: string;
}

/** Adds nested create feature */
export interface QuoteByHash {
  readonly billing_info: Record<string, any>;
  contact?: number | null;
  /** @format date-time */
  readonly created_at: string;
  created_by_manager?: boolean;
  default_price_option?: number | null;
  discount_usage: DiscountUsage[];
  /**
   * km
   * @format decimal
   * @pattern ^-?\d{0,5}(?:\.\d{0,2})?$
   */
  distance?: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  duration?: string | null;
  /** @format date-time */
  expiration_time?: string | null;
  /** Full truck load */
  ftl?: boolean;
  readonly goods: Good[];
  /**
   * Hash key
   * @maxLength 64
   */
  hash?: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  insurance_limit?: string | null;
  is_magic_link_visited?: boolean;
  /** Operator */
  manager_account?: number | null;
  /** Notes */
  note?: string;
  notify_on_app_launch?: boolean;
  /** @format date-time */
  paid_datetime?: string | null;
  payment_method?: PaymentMethodEnum | BlankEnum;
  polyline?: string | null;
  /** @format date-time */
  price_option_set_time?: string | null;
  readonly price_options: PriceOption[];
  /** Custom quote */
  quote_custom?: boolean;
  /** Adds nested create feature */
  selected_price_option?: PriceOption;
  special_requirements?: SpecialRequirements;
  /**
   * * `DRAFT` - Draft
   * * `PRICEABLE` - Priceable
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `WITH_ORDER` - With Order
   */
  state?: StateEnum;
  /** @format date-time */
  readonly updated_at: string | null;
  user?: User;
  utm_parameters?: Utm;
  vehicle_type?: VehicleTypeEnum | BlankEnum;
  waypoints?: Waypoint[];
}

/** Adds nested create feature */
export interface QuoteByID {
  /** Billing information */
  billing_info?: number | null;
  contact?: Contact;
  /** @format date-time */
  readonly created_at: string;
  created_by_manager?: boolean;
  default_price_option?: number | null;
  /**
   * km
   * @format decimal
   * @pattern ^-?\d{0,5}(?:\.\d{0,2})?$
   */
  distance?: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  duration?: string | null;
  /** @format date-time */
  expiration_time?: string | null;
  /** Full truck load */
  ftl?: boolean;
  goods?: Good[];
  readonly id: number;
  is_magic_link_visited?: boolean;
  /** Operator */
  manager_account?: number | null;
  /** Notes */
  note?: string;
  notify_on_app_launch?: boolean;
  polyline?: string | null;
  /** @format date-time */
  price_option_set_time?: string | null;
  /** Custom quote */
  quote_custom?: boolean;
  selected_price_option?: number | null;
  special_requirements?: SpecialRequirements;
  /**
   * * `DRAFT` - Draft
   * * `PRICEABLE` - Priceable
   * * `CONFIRMED` - Confirmed
   * * `CANCELED` - Canceled
   * * `WITH_ORDER` - With Order
   */
  state?: StateEnum;
  /** @format date-time */
  readonly updated_at: string | null;
  user?: User;
  utm_parameters?: Utm;
  /** @format uuid */
  uuid?: string;
  vehicle_type?: VehicleTypeEnum | BlankEnum;
  waypoints?: Waypoint[];
}

/** Adds nested create feature */
export interface QuoteWithOrder {
  readonly billing_info: Record<string, any>;
  readonly contact: Contact;
  /**
   * €
   * @format decimal
   * @pattern ^-?\d{0,5}(?:\.\d{0,2})?$
   */
  readonly cost_price: string | null;
  /** @format date-time */
  readonly created_at: string;
  readonly created_by_manager: boolean;
  readonly default_price_option: number | null;
  readonly discount_usage: DiscountUsage[];
  /**
   * km
   * @format decimal
   * @pattern ^-?\d{0,5}(?:\.\d{0,2})?$
   */
  readonly distance: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly duration: string | null;
  /** @format date-time */
  readonly expiration_time: string | null;
  /** Full truck load */
  readonly ftl: boolean;
  readonly goods: Good[];
  /**
   * Hash key
   * @maxLength 64
   */
  readonly hash: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  readonly insurance_limit: string | null;
  readonly is_magic_link_visited: boolean;
  readonly manager_account: User;
  /** Notes */
  readonly note: string;
  readonly notify_on_app_launch: boolean;
  readonly order: Order;
  /** @format date-time */
  readonly paid_datetime: string | null;
  readonly payment_method: PaymentMethodEnum | BlankEnum;
  readonly polyline: string | null;
  /**
   * €
   * @format decimal
   * @pattern ^-?\d{0,5}(?:\.\d{0,2})?$
   */
  readonly price: string | null;
  /** @format date-time */
  readonly price_option_set_time: string | null;
  /** Custom quote */
  readonly quote_custom: boolean;
  readonly selected_price_option: PriceOption;
  readonly special_requirements: SpecialRequirements;
  readonly state: StateEnum;
  /** @format date-time */
  readonly updated_at: string | null;
  readonly user: User;
  readonly utm_parameters: number | null;
  readonly vehicle_type: VehicleTypeEnum | BlankEnum;
  readonly waypoints: Waypoint[];
}

export type QuotesByHashCofaceTaskCreateError = Record<string, any>;

export type QuotesByHashUpdateBillingCreatePayload = Record<string, any>;

export type QuotesByIdUpdateWaypointCreateError = Record<string, any>;

export interface Register {
  password1: string;
  password2: string;
  city?: string;
  company_name?: string;
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia (Federated States of)
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country?: CountryEnum;
  /** @format email */
  email: string;
  is_company: boolean;
  is_vat_payer?: boolean;
  name: string;
  phone?: string;
  postcode?: string;
  street?: string;
  street_number?: string;
  timezone?: string;
  /**
   * @minLength 1
   * @maxLength 0
   */
  username?: string;
  vat_number?: string;
}

export interface ResendEmailVerification {
  /** @format email */
  email: string;
}

export interface RestAuthDetail {
  readonly detail: string;
}

export interface RouteDetailsRequest {
  destination: Location;
  origin: Location;
  vehicle_type?: VehicleTypeEnum | BlankEnum;
}

export interface RouteDetailsResponse {
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  distance: string;
  /**
   * @format decimal
   * @pattern ^-?\d{0,8}(?:\.\d{0,2})?$
   */
  duration: string;
  polyline: string;
  via: string[] | null;
}

/**
 * * `LTL_DISALLOWED_COUNTRY` - LTL_DISALLOWED_COUNTRY
 * * `LTL_DISALLOWED_REGION` - LTL_DISALLOWED_REGION
 * * `DISALLOWED_REGION` - DISALLOWED_REGION
 * * `LTL_PARTIALLY_ALLOWED_REGION` - LTL_PARTIALLY_ALLOWED_REGION
 * * `LTL_PARTIALLY_ALLOWED_COUNTRY` - LTL_PARTIALLY_ALLOWED_COUNTRY
 * * `EXPENSIVE_REGION` - EXPENSIVE_REGION
 */
export enum RuleTypeNameEnum {
  LTL_DISALLOWED_COUNTRY = "LTL_DISALLOWED_COUNTRY",
  LTL_DISALLOWED_REGION = "LTL_DISALLOWED_REGION",
  DISALLOWED_REGION = "DISALLOWED_REGION",
  LTL_PARTIALLY_ALLOWED_REGION = "LTL_PARTIALLY_ALLOWED_REGION",
  LTL_PARTIALLY_ALLOWED_COUNTRY = "LTL_PARTIALLY_ALLOWED_COUNTRY",
  EXPENSIVE_REGION = "EXPENSIVE_REGION",
}

export interface SchemaRetrieveParams {
  format?: SchemaRetrieveParamsFormat;
  lang?: SchemaRetrieveParamsLang;
}

export interface SpecialRequirements {
  /** a treaty governing transport of hazardous materials */
  adr?: boolean;
  /**
   * Driver assisted loading
   * Service where the driver actively assists in the loading of goods onto the vehicle.
   */
  dal?: boolean;
  /**
   * Driver assisted loading
   * Fixed price for service where the driver actively assists in the loading of goods onto the vehicle.
   * @format double
   * @min 0
   */
  dal_price?: number | null;
  /**
   * Driver assisted unloading
   * Service where the driver actively assists in the unloading of goods onto the vehicle.
   */
  dau?: boolean;
  /**
   * Driver assisted unloading
   * Fixed price for service where the driver actively assists in the unloading of goods onto the vehicle.
   * @format double
   * @min 0
   */
  dau_price?: number | null;
  /**
   * Extended vehicle loading area
   * Loading area with extended length or height.
   */
  extended_vehicle?: boolean;
  /** @format double */
  extended_vehicle_price?: number | null;
  /** refrigerator truck */
  frigo?: boolean;
  readonly id: number;
  tail_lift?: boolean;
  /** If there is a necessary downtime of the vehicle due to longer loading or unloading of goods. */
  vehicle_downtime?: boolean;
  /** @format double */
  vehicle_downtime_counted_price?: number | null;
  /**
   * @format double
   * @min 0
   */
  vehicle_downtime_price?: number | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  vehicle_downtime_time?: number | null;
}

/**
 * * `DRAFT` - Draft
 * * `PRICEABLE` - Priceable
 * * `CONFIRMED` - Confirmed
 * * `CANCELED` - Canceled
 * * `WITH_ORDER` - With Order
 */
export enum StateEnum {
  DRAFT = "DRAFT",
  PRICEABLE = "PRICEABLE",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  WITH_ORDER = "WITH_ORDER",
}

/**
 * * `EXPRESS` - express
 * * `STANDARD` - standard
 * * `ECONOMY` - economy
 * * `CUSTOM` - custom
 */
export enum TitleEnum {
  EXPRESS = "EXPRESS",
  STANDARD = "STANDARD",
  ECONOMY = "ECONOMY",
  CUSTOM = "CUSTOM",
}

export interface TokenRefresh {
  readonly access: string;
  refresh: string;
}

export interface TokenVerify {
  token: string;
}

/** Adds nested create feature */
export interface Transport {
  /** @format date-time */
  readonly created_at: string;
  dispatcher?: User;
  location_updates?: TransportUpdate[];
  /** @maxLength 50 */
  plate_number?: string | null;
  /** @maxLength 50 */
  plate_number_trailer?: string;
  polyline?: string | null;
  /**
   * * `APPROVED` - approved
   * * `CANCELLED` - cancelled
   */
  status?: TransportStatusEnum;
  /** @format date-time */
  readonly updated_at: string | null;
  /** Vehicle type swida */
  vehicle_type?: TransportVehicleTypeEnum | BlankEnum;
}

export interface TransportCategory {
  description: string;
  /**
   * Duration of the timeframe in hours
   * @min -2147483648
   * @max 2147483647
   */
  duration_hours: number;
  readonly id: number;
  /**
   * Hours to move from the current time to start the timeframe
   * @min -2147483648
   * @max 2147483647
   */
  offset_hours: number;
  /**
   * * `EXPRESS` - express
   * * `STANDARD` - standard
   * * `ECONOMY` - economy
   * * `CUSTOM` - custom
   */
  title?: TitleEnum;
}

/**
 * * `SEARCHING_FOR_THE_VEHICLE` - Searching for the vehicle
 * * `VEHICLE_FOUND` - Vehicle found
 * * `LOADED` - Loaded
 * * `ON_ROAD` - On Road
 * * `UNLOADED` - Unloaded
 */
export enum TransportProgressEnum {
  SEARCHING_FOR_THE_VEHICLE = "SEARCHING_FOR_THE_VEHICLE",
  VEHICLE_FOUND = "VEHICLE_FOUND",
  LOADED = "LOADED",
  ON_ROAD = "ON_ROAD",
  UNLOADED = "UNLOADED",
}

export interface TransportRestriction {
  country_code: string;
  region_list?: string[] | null;
  /**
   * * `LTL_DISALLOWED_COUNTRY` - LTL_DISALLOWED_COUNTRY
   * * `LTL_DISALLOWED_REGION` - LTL_DISALLOWED_REGION
   * * `DISALLOWED_REGION` - DISALLOWED_REGION
   * * `LTL_PARTIALLY_ALLOWED_REGION` - LTL_PARTIALLY_ALLOWED_REGION
   * * `LTL_PARTIALLY_ALLOWED_COUNTRY` - LTL_PARTIALLY_ALLOWED_COUNTRY
   * * `EXPENSIVE_REGION` - EXPENSIVE_REGION
   */
  rule_type_name: RuleTypeNameEnum;
}

/**
 * * `APPROVED` - approved
 * * `CANCELLED` - cancelled
 */
export enum TransportStatusEnum {
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
}

/** Deprecated, will be removed in django-rest-framework-gis 1.0 */
export interface TransportUpdate {
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  datetime?: string | null;
  /**
   * km
   * @min 0
   * @max 32767
   */
  distance?: number | null;
  /**
   * * `SWIDA` - Swida
   * * `TRANSPORTLY` - Transportly
   */
  origin?: OriginEnum;
  point?: {
    type?: TransportUpdateType;
    /**
     * @maxItems 3
     * @minItems 2
     * @example [12.9721,77.5933]
     */
    coordinates?: number[];
  } | null;
  /**
   * * `ON_ROAD` - on road
   * * `PICKUP_ARRIVED` - pickup arrived
   * * `DELIVERY_ARRIVED` - delivery arrived
   * * `LOADED` - loaded
   * * `UNLOADED` - unloaded
   */
  status?: TransportUpdateStatusEnum;
  /** @format date-time */
  readonly updated_at: string | null;
}

/**
 * * `ON_ROAD` - on road
 * * `PICKUP_ARRIVED` - pickup arrived
 * * `DELIVERY_ARRIVED` - delivery arrived
 * * `LOADED` - loaded
 * * `UNLOADED` - unloaded
 */
export enum TransportUpdateStatusEnum {
  ON_ROAD = "ON_ROAD",
  PICKUP_ARRIVED = "PICKUP_ARRIVED",
  DELIVERY_ARRIVED = "DELIVERY_ARRIVED",
  LOADED = "LOADED",
  UNLOADED = "UNLOADED",
}

/**
 * * `CAR` - Pick-up
 * * `VAN` - Sprinter (up to 1.5t)
 * * `SMALL_LORRY` - Small lorry (up to 3.2t)
 * * `BIG_LORRY` - Big lorry (up to 7t)
 * * `LKW` - LKW
 */
export enum TransportVehicleTypeEnum {
  CAR = "CAR",
  VAN = "VAN",
  SMALL_LORRY = "SMALL_LORRY",
  BIG_LORRY = "BIG_LORRY",
  LKW = "LKW",
}

/**
 * * `pickup` - pickup
 * * `delivery` - delivery
 */
export enum TypeEnum {
  Pickup = "pickup",
  Delivery = "delivery",
}

export interface UpdatePayment {
  payment_method?: PaymentMethodEnum | BlankEnum | NullEnum | null;
}

/** Adds nested create feature */
export interface UpdateQuoteSpecialRequirements {
  special_requirements: SpecialRequirements;
}

/** Adds nested create feature */
export interface UpdateQuoteWaypoints {
  waypoints: UpdateWaypoint[];
}

/** Adds nested create feature */
export interface UpdateSelectedPriceOption {
  /** Adds nested create feature */
  selected_price_option: PriceOption;
}

export interface UpdateUserBillingType {
  /**
   * * `AF` - Afghanistan
   * * `AX` - Åland Islands
   * * `AL` - Albania
   * * `DZ` - Algeria
   * * `AS` - American Samoa
   * * `AD` - Andorra
   * * `AO` - Angola
   * * `AI` - Anguilla
   * * `AQ` - Antarctica
   * * `AG` - Antigua and Barbuda
   * * `AR` - Argentina
   * * `AM` - Armenia
   * * `AW` - Aruba
   * * `AU` - Australia
   * * `AT` - Austria
   * * `AZ` - Azerbaijan
   * * `BS` - Bahamas
   * * `BH` - Bahrain
   * * `BD` - Bangladesh
   * * `BB` - Barbados
   * * `BY` - Belarus
   * * `BE` - Belgium
   * * `BZ` - Belize
   * * `BJ` - Benin
   * * `BM` - Bermuda
   * * `BT` - Bhutan
   * * `BO` - Bolivia
   * * `BQ` - Bonaire, Sint Eustatius and Saba
   * * `BA` - Bosnia and Herzegovina
   * * `BW` - Botswana
   * * `BV` - Bouvet Island
   * * `BR` - Brazil
   * * `IO` - British Indian Ocean Territory
   * * `BN` - Brunei
   * * `BG` - Bulgaria
   * * `BF` - Burkina Faso
   * * `BI` - Burundi
   * * `CV` - Cabo Verde
   * * `KH` - Cambodia
   * * `CM` - Cameroon
   * * `CA` - Canada
   * * `KY` - Cayman Islands
   * * `CF` - Central African Republic
   * * `TD` - Chad
   * * `CL` - Chile
   * * `CN` - China
   * * `CX` - Christmas Island
   * * `CC` - Cocos (Keeling) Islands
   * * `CO` - Colombia
   * * `KM` - Comoros
   * * `CG` - Congo
   * * `CD` - Congo (the Democratic Republic of the)
   * * `CK` - Cook Islands
   * * `CR` - Costa Rica
   * * `CI` - Côte d'Ivoire
   * * `HR` - Croatia
   * * `CU` - Cuba
   * * `CW` - Curaçao
   * * `CY` - Cyprus
   * * `CZ` - Czechia
   * * `DK` - Denmark
   * * `DJ` - Djibouti
   * * `DM` - Dominica
   * * `DO` - Dominican Republic
   * * `EC` - Ecuador
   * * `EG` - Egypt
   * * `SV` - El Salvador
   * * `GQ` - Equatorial Guinea
   * * `ER` - Eritrea
   * * `EE` - Estonia
   * * `SZ` - Eswatini
   * * `ET` - Ethiopia
   * * `FK` - Falkland Islands (Malvinas)
   * * `FO` - Faroe Islands
   * * `FJ` - Fiji
   * * `FI` - Finland
   * * `FR` - France
   * * `GF` - French Guiana
   * * `PF` - French Polynesia
   * * `TF` - French Southern Territories
   * * `GA` - Gabon
   * * `GM` - Gambia
   * * `GE` - Georgia
   * * `DE` - Germany
   * * `GH` - Ghana
   * * `GI` - Gibraltar
   * * `GR` - Greece
   * * `GL` - Greenland
   * * `GD` - Grenada
   * * `GP` - Guadeloupe
   * * `GU` - Guam
   * * `GT` - Guatemala
   * * `GG` - Guernsey
   * * `GN` - Guinea
   * * `GW` - Guinea-Bissau
   * * `GY` - Guyana
   * * `HT` - Haiti
   * * `HM` - Heard Island and McDonald Islands
   * * `VA` - Holy See
   * * `HN` - Honduras
   * * `HK` - Hong Kong
   * * `HU` - Hungary
   * * `IS` - Iceland
   * * `IN` - India
   * * `ID` - Indonesia
   * * `IR` - Iran
   * * `IQ` - Iraq
   * * `IE` - Ireland
   * * `IM` - Isle of Man
   * * `IL` - Israel
   * * `IT` - Italy
   * * `JM` - Jamaica
   * * `JP` - Japan
   * * `JE` - Jersey
   * * `JO` - Jordan
   * * `KZ` - Kazakhstan
   * * `KE` - Kenya
   * * `KI` - Kiribati
   * * `KW` - Kuwait
   * * `KG` - Kyrgyzstan
   * * `LA` - Laos
   * * `LV` - Latvia
   * * `LB` - Lebanon
   * * `LS` - Lesotho
   * * `LR` - Liberia
   * * `LY` - Libya
   * * `LI` - Liechtenstein
   * * `LT` - Lithuania
   * * `LU` - Luxembourg
   * * `MO` - Macao
   * * `MG` - Madagascar
   * * `MW` - Malawi
   * * `MY` - Malaysia
   * * `MV` - Maldives
   * * `ML` - Mali
   * * `MT` - Malta
   * * `MH` - Marshall Islands
   * * `MQ` - Martinique
   * * `MR` - Mauritania
   * * `MU` - Mauritius
   * * `YT` - Mayotte
   * * `MX` - Mexico
   * * `FM` - Micronesia (Federated States of)
   * * `MD` - Moldova
   * * `MC` - Monaco
   * * `MN` - Mongolia
   * * `ME` - Montenegro
   * * `MS` - Montserrat
   * * `MA` - Morocco
   * * `MZ` - Mozambique
   * * `MM` - Myanmar
   * * `NA` - Namibia
   * * `NR` - Nauru
   * * `NP` - Nepal
   * * `NL` - Netherlands
   * * `NC` - New Caledonia
   * * `NZ` - New Zealand
   * * `NI` - Nicaragua
   * * `NE` - Niger
   * * `NG` - Nigeria
   * * `NU` - Niue
   * * `NF` - Norfolk Island
   * * `KP` - North Korea
   * * `MK` - North Macedonia
   * * `MP` - Northern Mariana Islands
   * * `NO` - Norway
   * * `OM` - Oman
   * * `PK` - Pakistan
   * * `PW` - Palau
   * * `PS` - Palestine, State of
   * * `PA` - Panama
   * * `PG` - Papua New Guinea
   * * `PY` - Paraguay
   * * `PE` - Peru
   * * `PH` - Philippines
   * * `PN` - Pitcairn
   * * `PL` - Poland
   * * `PT` - Portugal
   * * `PR` - Puerto Rico
   * * `QA` - Qatar
   * * `RE` - Réunion
   * * `RO` - Romania
   * * `RU` - Russia
   * * `RW` - Rwanda
   * * `BL` - Saint Barthélemy
   * * `SH` - Saint Helena, Ascension and Tristan da Cunha
   * * `KN` - Saint Kitts and Nevis
   * * `LC` - Saint Lucia
   * * `MF` - Saint Martin (French part)
   * * `PM` - Saint Pierre and Miquelon
   * * `VC` - Saint Vincent and the Grenadines
   * * `WS` - Samoa
   * * `SM` - San Marino
   * * `ST` - Sao Tome and Principe
   * * `SA` - Saudi Arabia
   * * `SN` - Senegal
   * * `RS` - Serbia
   * * `SC` - Seychelles
   * * `SL` - Sierra Leone
   * * `SG` - Singapore
   * * `SX` - Sint Maarten (Dutch part)
   * * `SK` - Slovakia
   * * `SI` - Slovenia
   * * `SB` - Solomon Islands
   * * `SO` - Somalia
   * * `ZA` - South Africa
   * * `GS` - South Georgia and the South Sandwich Islands
   * * `KR` - South Korea
   * * `SS` - South Sudan
   * * `ES` - Spain
   * * `LK` - Sri Lanka
   * * `SD` - Sudan
   * * `SR` - Suriname
   * * `SJ` - Svalbard and Jan Mayen
   * * `SE` - Sweden
   * * `CH` - Switzerland
   * * `SY` - Syria
   * * `TW` - Taiwan
   * * `TJ` - Tajikistan
   * * `TZ` - Tanzania
   * * `TH` - Thailand
   * * `TL` - Timor-Leste
   * * `TG` - Togo
   * * `TK` - Tokelau
   * * `TO` - Tonga
   * * `TT` - Trinidad and Tobago
   * * `TN` - Tunisia
   * * `TR` - Türkiye
   * * `TM` - Turkmenistan
   * * `TC` - Turks and Caicos Islands
   * * `TV` - Tuvalu
   * * `UG` - Uganda
   * * `UA` - Ukraine
   * * `AE` - United Arab Emirates
   * * `GB` - United Kingdom
   * * `UM` - United States Minor Outlying Islands
   * * `US` - United States of America
   * * `UY` - Uruguay
   * * `UZ` - Uzbekistan
   * * `VU` - Vanuatu
   * * `VE` - Venezuela
   * * `VN` - Vietnam
   * * `VG` - Virgin Islands (British)
   * * `VI` - Virgin Islands (U.S.)
   * * `WF` - Wallis and Futuna
   * * `EH` - Western Sahara
   * * `YE` - Yemen
   * * `ZM` - Zambia
   * * `ZW` - Zimbabwe
   */
  country?: CountryEnum;
  /** User is company? */
  is_company?: boolean;
  /** User is vat payer? */
  is_vat_payer?: boolean;
}

export interface UpdateWaypoint {
  name?: string;
  street?: string;
  street_number?: string;
}

export interface User {
  /** @maxLength 100 */
  city?: string;
  /** @maxLength 100 */
  company_name?: string;
  country?: CountryEnum | BlankEnum | NullEnum | null;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string;
  /** User is company? */
  is_company?: boolean;
  /** User is vat payer? */
  is_vat_payer?: boolean;
  /**
   * User name
   * @maxLength 255
   */
  name: string;
  /** @maxLength 128 */
  phone?: string;
  /**
   * Post code
   * @maxLength 20
   */
  postcode?: string;
  /** @maxLength 100 */
  street?: string;
  /** @maxLength 100 */
  street_number?: string;
  timezone?: string;
  /** @maxLength 150 */
  vat_number?: string;
}

/** User model w/o password */
export interface UserDetails {
  /** @maxLength 100 */
  city?: string;
  /** @maxLength 100 */
  company_name?: string;
  country?: CountryEnum | BlankEnum;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string;
  readonly id: number;
  /** User is company? */
  is_company?: boolean;
  readonly is_email_verified: boolean;
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  readonly is_staff: boolean;
  /** User is vat payer? */
  is_vat_payer?: boolean;
  /**
   * User name
   * @maxLength 255
   */
  name: string;
  /** @maxLength 128 */
  phone?: string;
  /**
   * Post code
   * @maxLength 20
   */
  postcode?: string;
  /** @maxLength 100 */
  street?: string;
  /** @maxLength 100 */
  street_number?: string;
  timezone?: string;
  /** @maxLength 150 */
  vat_number?: string;
}

export interface Utm {
  /** @maxLength 255 */
  utm_campaign?: string | null;
  /** @maxLength 255 */
  utm_content?: string | null;
  /** @maxLength 255 */
  utm_medium?: string | null;
  /** @maxLength 255 */
  utm_source?: string | null;
}

/**
 * * `VAN` - VAN
 * * `SMALL_LORRY` - SMALL_LORRY
 * * `BIG_LORRY` - BIG_LORRY
 * * `LKW` - LKW
 */
export enum VehicleTypeEnum {
  VAN = "VAN",
  SMALL_LORRY = "SMALL_LORRY",
  BIG_LORRY = "BIG_LORRY",
  LKW = "LKW",
}

export interface VerifyEmail {
  key: string;
}

/** Deprecated, will be removed in django-rest-framework-gis 1.0 */
export interface Waypoint {
  /** @maxLength 50 */
  city?: string;
  country?: CountryEnum | BlankEnum;
  /** @format date-time */
  readonly created_at: string;
  /** @format date-time */
  datetime?: string | null;
  readonly id: number;
  /**
   * Full name / company
   * @maxLength 100
   */
  name?: string;
  /** GPS coordinates */
  point?: {
    type?: WaypointType;
    /**
     * @maxItems 3
     * @minItems 2
     * @example [12.9721,77.5933]
     */
    coordinates?: number[];
  } | null;
  /** @maxLength 30 */
  postcode?: string;
  /**
   * @min 0
   * @max 32767
   */
  sequence?: number | null;
  /** @maxLength 200 */
  street?: string;
  /** @maxLength 200 */
  street_number?: string;
  type?: TypeEnum | BlankEnum;
  /** @format date-time */
  readonly updated_at: string | null;
}

export enum SchemaRetrieveParamsFormat {
  Json = "json",
  Yaml = "yaml",
}

export enum SchemaRetrieveParamsLang {
  De = "de",
  En = "en",
  Sk = "sk",
}

export enum TransportUpdateType {
  Point = "Point",
}

export enum WaypointType {
  Point = "Point",
}

export namespace CheckEmailVerification {
  /**
   * @description API endpoint to check if an email is verified.
   * @tags email-verification
   * @name AuthCheckEmailVerificationRetrieve
   * @request GET:/api/auth/check-email-verification/
   * @secure
   * @response `200` `void` Verification status
   * @response `400` `void` Invalid email or request format
   */
  export namespace AuthCheckEmailVerificationRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {
      /** Email address */
      email: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Login {
  /**
   * @description Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
   * @tags auth
   * @name AuthLoginCreate
   * @request POST:/api/auth/login/
   * @secure
   * @response `200` `JWT`
   */
  export namespace AuthLoginCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Login;
    export type RequestHeaders = {};
    export type ResponseBody = JWT;
  }
}

export namespace Logout {
  /**
   * @description Calls Django logout method and delete the Token object assigned to the current User object. Accepts/Returns nothing.
   * @tags auth
   * @name AuthLogoutCreate
   * @request POST:/api/auth/logout/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthLogoutCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }
}

export namespace Password {
  /**
   * @description Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
   * @tags auth
   * @name AuthPasswordChangeCreate
   * @request POST:/api/auth/password/change/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthPasswordChangeCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PasswordChange;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }
  /**
   * @description Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
   * @tags auth
   * @name AuthPasswordResetCreate
   * @request POST:/api/auth/password/reset/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthPasswordResetCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PasswordReset;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }
  /**
   * @description Password reset e-mail link is confirmed, therefore this resets the user's password. Accepts the following POST parameters: token, uid, new_password1, new_password2 Returns the success/fail message.
   * @tags auth
   * @name AuthPasswordResetConfirmCreate
   * @request POST:/api/auth/password/reset/confirm/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthPasswordResetConfirmCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PasswordResetConfirm;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }
}

export namespace Register {
  /**
   * No description
   * @tags auth
   * @name AuthRegisterCreate
   * @request POST:/api/auth/register/
   * @secure
   * @response `201` `Register`
   */
  export namespace AuthRegisterCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Register;
    export type RequestHeaders = {};
    export type ResponseBody = Register;
  }
  /**
   * No description
   * @tags auth
   * @name AuthRegisterResendEmailCreate
   * @request POST:/api/auth/register/resend-email/
   * @secure
   * @response `201` `RestAuthDetail`
   */
  export namespace AuthRegisterResendEmailCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ResendEmailVerification;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }
  /**
   * No description
   * @tags auth
   * @name AuthRegisterVerifyEmailCreate
   * @request POST:/api/auth/register/verify-email/
   * @secure
   * @response `200` `RestAuthDetail`
   */
  export namespace AuthRegisterVerifyEmailCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = VerifyEmail;
    export type RequestHeaders = {};
    export type ResponseBody = RestAuthDetail;
  }
}

export namespace Token {
  /**
   * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
   * @tags auth
   * @name AuthTokenRefreshCreate
   * @request POST:/api/auth/token/refresh/
   * @response `200` `TokenRefresh`
   */
  export namespace AuthTokenRefreshCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TokenRefresh;
    export type RequestHeaders = {};
    export type ResponseBody = TokenRefresh;
  }
  /**
   * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
   * @tags auth
   * @name AuthTokenVerifyCreate
   * @request POST:/api/auth/token/verify/
   * @response `200` `TokenVerify`
   */
  export namespace AuthTokenVerifyCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TokenVerify;
    export type RequestHeaders = {};
    export type ResponseBody = TokenVerify;
  }
}

export namespace User {
  /**
   * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
   * @tags auth
   * @name AuthUserRetrieve
   * @request GET:/api/auth/user/
   * @secure
   * @response `200` `UserDetails`
   */
  export namespace AuthUserRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserDetails;
  }
  /**
   * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
   * @tags auth
   * @name AuthUserUpdate
   * @request PUT:/api/auth/user/
   * @secure
   * @response `200` `UserDetails`
   */
  export namespace AuthUserUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UserDetails;
    export type RequestHeaders = {};
    export type ResponseBody = UserDetails;
  }
  /**
   * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
   * @tags auth
   * @name AuthUserPartialUpdate
   * @request PATCH:/api/auth/user/
   * @secure
   * @response `200` `UserDetails`
   */
  export namespace AuthUserPartialUpdate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = PatchedUserDetails;
    export type RequestHeaders = {};
    export type ResponseBody = UserDetails;
  }
}

export namespace GetDefaultPriceSettings {
  /**
   * @description API endpoint to retrieve default price settings.
   * @tags default-price-settings
   * @name DefaultPriceSettingsGetDefaultPriceSettingsRetrieve
   * @request GET:/api/default-price-settings/get-default-price-settings/
   * @secure
   * @response `200` `DefaultPriceSettings`
   */
  export namespace DefaultPriceSettingsGetDefaultPriceSettingsRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = DefaultPriceSettings;
  }
}

export namespace Apply {
  /**
   * @description API endpoint to apply a discount code to a quote.
   * @tags discounts
   * @name DiscountsApplyCreate
   * @request POST:/api/discounts/apply/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace DiscountsApplyCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = ApplyDiscount;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
}

export namespace GetFeesPrices {
  /**
   * @description API endpoint to retrieve extra fees.
   * @tags extra-fees
   * @name ExtraFeesGetFeesPricesRetrieve
   * @request GET:/api/extra-fees/get-fees-prices/
   * @secure
   * @response `200` `ExtraFees`
   */
  export namespace ExtraFeesGetFeesPricesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ExtraFees;
  }
}

export namespace Countries {
  /**
   * No description
   * @tags options
   * @name OptionsCountriesRetrieve
   * @request GET:/api/options/countries/
   * @secure
   * @response `200` `Record<string,any>`
   */
  export namespace OptionsCountriesRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }
}

export namespace Quote {
  /**
   * @description API endpoints that allow orders to be viewed or edited.
   * @tags orders
   * @name OrdersRetrieve
   * @request GET:/api/orders/{quote}/
   * @secure
   * @response `200` `Order`
   */
  export namespace OrdersRetrieve {
    export type RequestParams = {
      quote: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Order;
  }
  /**
   * @description API endpoints that allow orders to be viewed or edited.
   * @tags orders
   * @name OrdersUpdate
   * @request PUT:/api/orders/{quote}/
   * @secure
   * @response `200` `Order` Successfully updated the order.
   * @response `400` `void` Invalid input.
   * @response `404` `void` Order not found.
   */
  export namespace OrdersUpdate {
    export type RequestParams = {
      quote: number;
    };
    export type RequestQuery = {};
    export type RequestBody = Order;
    export type RequestHeaders = {};
    export type ResponseBody = Order;
  }
  /**
   * @description API endpoints that allow orders to be viewed or edited.
   * @tags orders
   * @name OrdersPartialUpdate
   * @request PATCH:/api/orders/{quote}/
   * @secure
   * @response `200` `Order`
   */
  export namespace OrdersPartialUpdate {
    export type RequestParams = {
      quote: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedOrder;
    export type RequestHeaders = {};
    export type ResponseBody = Order;
  }
}

export namespace CheckoutSession {
  /**
   * No description
   * @tags payments
   * @name PaymentsCheckoutSessionCreate
   * @request POST:/api/payments/checkout-session/{hash}/
   * @secure
   * @response `200` `CheckoutResponse`
   */
  export namespace PaymentsCheckoutSessionCreate {
    export type RequestParams = {
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CheckoutResponse;
  }
}

export namespace Hash {
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashRetrieve
   * @request GET:/api/quotes-by-hash/{hash}/
   * @secure
   * @response `200` `QuoteByHash` Successfully retrieved quote.
   */
  export namespace QuotesByHashRetrieve {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashUpdate
   * @request PUT:/api/quotes-by-hash/{hash}/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace QuotesByHashUpdate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = QuoteByHash;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashPartialUpdate
   * @request PATCH:/api/quotes-by-hash/{hash}/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace QuotesByHashPartialUpdate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedQuoteByHash;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashAutofillCompanyCreate
   * @request POST:/api/quotes-by-hash/{hash}/autofill-company/
   * @secure
   * @response `200` `CofaceCompanyDataResponse`
   */
  export namespace QuotesByHashAutofillCompanyCreate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = CofaceCompanyResponse;
    export type RequestHeaders = {};
    export type ResponseBody = CofaceCompanyDataResponse;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashCofaceTaskCreate
   * @request POST:/api/quotes-by-hash/{hash}/coface-task/
   * @secure
   * @response `200` `number`
   * @response `400` `Record<string,any>`
   */
  export namespace QuotesByHashCofaceTaskCreate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = number;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashConfirmCreate
   * @request POST:/api/quotes-by-hash/{hash}/confirm/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace QuotesByHashConfirmCreate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashUpdateBillingCreate
   * @request POST:/api/quotes-by-hash/{hash}/update-billing/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace QuotesByHashUpdateBillingCreate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = QuotesByHashUpdateBillingCreatePayload;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashUpdatePaymentCreate
   * @request POST:/api/quotes-by-hash/{hash}/update-payment/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace QuotesByHashUpdatePaymentCreate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdatePayment;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashUpdateQuoteUserTypeCreate
   * @request POST:/api/quotes-by-hash/{hash}/update-quote-user-type/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace QuotesByHashUpdateQuoteUserTypeCreate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateUserBillingType;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description Update unfilled fields in waypoints
   * @tags quotes-by-hash
   * @name QuotesByHashUpdateQuoteWaypointsCreate
   * @request POST:/api/quotes-by-hash/{hash}/update-quote-waypoints/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace QuotesByHashUpdateQuoteWaypointsCreate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateQuoteWaypoints;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashUpdateSelectedPriceOptionCreate
   * @request POST:/api/quotes-by-hash/{hash}/update-selected-price-option/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace QuotesByHashUpdateSelectedPriceOptionCreate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateSelectedPriceOption;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashUpdateSpecialRequirementsCreate
   * @request POST:/api/quotes-by-hash/{hash}/update-special-requirements/
   * @secure
   * @response `200` `QuoteByHash`
   */
  export namespace QuotesByHashUpdateSpecialRequirementsCreate {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateQuoteSpecialRequirements;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByHash;
  }
  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   * @tags quotes-by-hash
   * @name QuotesByHashWithOrderRetrieve
   * @request GET:/api/quotes-by-hash/{hash}/with-order/
   * @secure
   * @response `200` `QuoteWithOrder`
   */
  export namespace QuotesByHashWithOrderRetrieve {
    export type RequestParams = {
      /** The Hash of the quote */
      hash: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteWithOrder;
  }
}

export namespace Uuid {
  /**
   * @description Mixin that disables the list and destroy actions.
   * @tags quotes-by-id
   * @name QuotesByIdUpdate
   * @request PUT:/api/quotes-by-id/{uuid}/
   * @secure
   * @response `200` `QuoteByID`
   */
  export namespace QuotesByIdUpdate {
    export type RequestParams = {
      /** @format uuid */
      uuid: string;
    };
    export type RequestQuery = {};
    export type RequestBody = QuoteByID;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByID;
  }
  /**
   * @description Mixin that disables the list and destroy actions.
   * @tags quotes-by-id
   * @name QuotesByIdPartialUpdate
   * @request PATCH:/api/quotes-by-id/{uuid}/
   * @secure
   * @response `200` `QuoteByID`
   */
  export namespace QuotesByIdPartialUpdate {
    export type RequestParams = {
      /** @format uuid */
      uuid: string;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedQuoteByID;
    export type RequestHeaders = {};
    export type ResponseBody = QuoteByID;
  }
  /**
   * @description Returns the default price for a given Quote
   * @tags quotes-by-id
   * @name QuotesByIdGetDefaultPriceCreate
   * @request POST:/api/quotes-by-id/{uuid}/get-default-price/
   * @secure
   * @response `200` `DefaultPriceResponse`
   */
  export namespace QuotesByIdGetDefaultPriceCreate {
    export type RequestParams = {
      /** @format uuid */
      uuid: string;
    };
    export type RequestQuery = {};
    export type RequestBody = DefaultPriceRequest;
    export type RequestHeaders = {};
    export type ResponseBody = DefaultPriceResponse;
  }
  /**
   * @description Retrieves detailed information about a route, including the best route and transit time
   * @tags quotes-by-id
   * @name QuotesByIdRouteDetailsCreate
   * @request POST:/api/quotes-by-id/{uuid}/route-details/
   * @secure
   * @response `200` `RouteDetailsResponse`
   */
  export namespace QuotesByIdRouteDetailsCreate {
    export type RequestParams = {
      /** @format uuid */
      uuid: string;
    };
    export type RequestQuery = {};
    export type RequestBody = RouteDetailsRequest;
    export type RequestHeaders = {};
    export type ResponseBody = RouteDetailsResponse;
  }
  /**
   * @description Update quote's waypoint with provided data
   * @tags quotes-by-id
   * @name QuotesByIdUpdateWaypointCreate
   * @request POST:/api/quotes-by-id/{uuid}/update-waypoint/
   * @secure
   * @response `200` `Waypoint`
   * @response `404` `Record<string,any>`
   */
  export namespace QuotesByIdUpdateWaypointCreate {
    export type RequestParams = {
      /** @format uuid */
      uuid: string;
    };
    export type RequestQuery = {};
    export type RequestBody = Waypoint;
    export type RequestHeaders = {};
    export type ResponseBody = Waypoint;
  }
}

export namespace Id {
  /**
   * @description API endpoints that allow users to be viewed or edited.
   * @tags users
   * @name UsersRetrieve
   * @request GET:/api/users/{id}/
   * @secure
   * @response `200` `User`
   */
  export namespace UsersRetrieve {
    export type RequestParams = {
      /** A unique integer value identifying this user. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
  /**
   * @description API endpoints that allow users to be viewed or edited.
   * @tags users
   * @name UsersUpdate
   * @request PUT:/api/users/{id}/
   * @secure
   * @response `200` `User`
   */
  export namespace UsersUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this user. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = User;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
  /**
   * @description API endpoints that allow users to be viewed or edited.
   * @tags users
   * @name UsersPartialUpdate
   * @request PATCH:/api/users/{id}/
   * @secure
   * @response `200` `User`
   */
  export namespace UsersPartialUpdate {
    export type RequestParams = {
      /** A unique integer value identifying this user. */
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = PatchedUser;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
}

export namespace Me {
  /**
   * @description API endpoints that allow users to be viewed or edited.
   * @tags users
   * @name UsersMeRetrieve
   * @request GET:/api/users/me/
   * @secure
   * @response `200` `User`
   */
  export namespace UsersMeRetrieve {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = User;
  }
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title instacarrier API
 * @version 1.0.0
 *
 * Documentation of API endpoints of instacarrier
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags email-domains-blacklist
   * @name EmailDomainsBlacklistList
   * @request GET:/api/email-domains-blacklist/
   * @secure
   * @response `200` `(BlacklistedEmailDomain)[]`
   */
  emailDomainsBlacklistList = (params: RequestParams = {}) =>
    this.request<BlacklistedEmailDomain[], any>({
      path: `/api/email-domains-blacklist/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });

  /**
   * @description API endpoint that allows feedbacks to be created.
   *
   * @tags feedbacks
   * @name FeedbacksCreate
   * @request POST:/api/feedbacks/
   * @secure
   * @response `200` `Feedback`
   */
  feedbacksCreate = (data: Feedback, params: RequestParams = {}) =>
    this.request<Feedback, any>({
      path: `/api/feedbacks/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * @description API endpoints that allow orders to be viewed or edited.
   *
   * @tags orders
   * @name OrdersCreate
   * @request POST:/api/orders/
   * @secure
   * @response `201` `Order`
   */
  ordersCreate = (data: Order, params: RequestParams = {}) =>
    this.request<Order, any>({
      path: `/api/orders/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * @description API endpoints that allow quotes to be viewed or edited.
   *
   * @tags quotes-by-hash
   * @name QuotesByHashCreate
   * @request POST:/api/quotes-by-hash/
   * @secure
   * @response `201` `QuoteByHash`
   */
  quotesByHashCreate = (hash: string, data: QuoteByHash, params: RequestParams = {}) =>
    this.request<QuoteByHash, any>({
      path: `/api/quotes-by-hash/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * @description Mixin that disables the list and destroy actions.
   *
   * @tags quotes-by-id
   * @name QuotesByIdCreate
   * @request POST:/api/quotes-by-id/
   * @secure
   * @response `201` `QuoteByID`
   */
  quotesByIdCreate = (data: QuoteByID, params: RequestParams = {}) =>
    this.request<QuoteByID, any>({
      path: `/api/quotes-by-id/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   *
   * @tags schema
   * @name SchemaRetrieve
   * @request GET:/api/schema/
   * @secure
   * @response `200` `Record<string,any>`
   */
  schemaRetrieve = (query: SchemaRetrieveParams, params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/schema/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });

  /**
   * @description Fetches transport restrictions grouped by vehicle type and country.
   *
   * @tags transport-restriction
   * @name TransportRestrictionList
   * @request GET:/api/transport-restriction/
   * @secure
   * @response `200` `(TransportRestriction)[]`
   */
  transportRestrictionList = (params: RequestParams = {}) =>
    this.request<TransportRestriction[], any>({
      path: `/api/transport-restriction/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });

  /**
   * @description API endpoints that allow users to be viewed or edited.
   *
   * @tags users
   * @name UsersList
   * @request GET:/api/users/
   * @secure
   * @response `200` `(User)[]`
   */
  usersList = (params: RequestParams = {}) =>
    this.request<User[], any>({
      path: `/api/users/`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });

  checkEmailVerification = {
    /**
     * @description API endpoint to check if an email is verified.
     *
     * @tags email-verification
     * @name AuthCheckEmailVerificationRetrieve
     * @request GET:/api/auth/check-email-verification/
     * @secure
     * @response `200` `void` Verification status
     * @response `400` `void` Invalid email or request format
     */
    authCheckEmailVerificationRetrieve: (query: AuthCheckEmailVerificationRetrieveParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/auth/check-email-verification/`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  login = {
    /**
     * @description Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework Accept the following POST parameters: username, password Return the REST Framework Token Object's key.
     *
     * @tags auth
     * @name AuthLoginCreate
     * @request POST:/api/auth/login/
     * @secure
     * @response `200` `JWT`
     */
    authLoginCreate: (data: Login, params: RequestParams = {}) =>
      this.request<JWT, any>({
        path: `/api/auth/login/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  logout = {
    /**
     * @description Calls Django logout method and delete the Token object assigned to the current User object. Accepts/Returns nothing.
     *
     * @tags auth
     * @name AuthLogoutCreate
     * @request POST:/api/auth/logout/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authLogoutCreate: (params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/auth/logout/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  password = {
    /**
     * @description Calls Django Auth SetPasswordForm save method. Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     *
     * @tags auth
     * @name AuthPasswordChangeCreate
     * @request POST:/api/auth/password/change/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authPasswordChangeCreate: (data: PasswordChange, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/auth/password/change/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Calls Django Auth PasswordResetForm save method. Accepts the following POST parameters: email Returns the success/fail message.
     *
     * @tags auth
     * @name AuthPasswordResetCreate
     * @request POST:/api/auth/password/reset/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authPasswordResetCreate: (data: PasswordReset, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/auth/password/reset/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Password reset e-mail link is confirmed, therefore this resets the user's password. Accepts the following POST parameters: token, uid, new_password1, new_password2 Returns the success/fail message.
     *
     * @tags auth
     * @name AuthPasswordResetConfirmCreate
     * @request POST:/api/auth/password/reset/confirm/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authPasswordResetConfirmCreate: (data: PasswordResetConfirm, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/auth/password/reset/confirm/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  register = {
    /**
     * No description
     *
     * @tags auth
     * @name AuthRegisterCreate
     * @request POST:/api/auth/register/
     * @secure
     * @response `201` `Register`
     */
    authRegisterCreate: (data: Register, params: RequestParams = {}) =>
      this.request<Register, any>({
        path: `/api/auth/register/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthRegisterResendEmailCreate
     * @request POST:/api/auth/register/resend-email/
     * @secure
     * @response `201` `RestAuthDetail`
     */
    authRegisterResendEmailCreate: (data: ResendEmailVerification, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/auth/register/resend-email/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthRegisterVerifyEmailCreate
     * @request POST:/api/auth/register/verify-email/
     * @secure
     * @response `200` `RestAuthDetail`
     */
    authRegisterVerifyEmailCreate: (data: VerifyEmail, params: RequestParams = {}) =>
      this.request<RestAuthDetail, any>({
        path: `/api/auth/register/verify-email/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  token = {
    /**
     * @description Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     *
     * @tags auth
     * @name AuthTokenRefreshCreate
     * @request POST:/api/auth/token/refresh/
     * @response `200` `TokenRefresh`
     */
    authTokenRefreshCreate: (data: TokenRefresh, params: RequestParams = {}) =>
      this.request<TokenRefresh, any>({
        path: `/api/auth/token/refresh/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Takes a token and indicates if it is valid.  This view provides no information about a token's fitness for a particular use.
     *
     * @tags auth
     * @name AuthTokenVerifyCreate
     * @request POST:/api/auth/token/verify/
     * @response `200` `TokenVerify`
     */
    authTokenVerifyCreate: (data: TokenVerify, params: RequestParams = {}) =>
      this.request<TokenVerify, any>({
        path: `/api/auth/token/verify/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
     *
     * @tags auth
     * @name AuthUserRetrieve
     * @request GET:/api/auth/user/
     * @secure
     * @response `200` `UserDetails`
     */
    authUserRetrieve: (params: RequestParams = {}) =>
      this.request<UserDetails, any>({
        path: `/api/auth/user/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
     *
     * @tags auth
     * @name AuthUserUpdate
     * @request PUT:/api/auth/user/
     * @secure
     * @response `200` `UserDetails`
     */
    authUserUpdate: (data: UserDetails, params: RequestParams = {}) =>
      this.request<UserDetails, any>({
        path: `/api/auth/user/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Reads and updates UserModel fields Accepts GET, PUT, PATCH methods. Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email Returns UserModel fields.
     *
     * @tags auth
     * @name AuthUserPartialUpdate
     * @request PATCH:/api/auth/user/
     * @secure
     * @response `200` `UserDetails`
     */
    authUserPartialUpdate: (data: PatchedUserDetails, params: RequestParams = {}) =>
      this.request<UserDetails, any>({
        path: `/api/auth/user/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getDefaultPriceSettings = {
    /**
     * @description API endpoint to retrieve default price settings.
     *
     * @tags default-price-settings
     * @name DefaultPriceSettingsGetDefaultPriceSettingsRetrieve
     * @request GET:/api/default-price-settings/get-default-price-settings/
     * @secure
     * @response `200` `DefaultPriceSettings`
     */
    defaultPriceSettingsGetDefaultPriceSettingsRetrieve: (params: RequestParams = {}) =>
      this.request<DefaultPriceSettings, any>({
        path: `/api/default-price-settings/get-default-price-settings/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  apply = {
    /**
     * @description API endpoint to apply a discount code to a quote.
     *
     * @tags discounts
     * @name DiscountsApplyCreate
     * @request POST:/api/discounts/apply/
     * @secure
     * @response `200` `QuoteByHash`
     */
    discountsApplyCreate: (data: ApplyDiscount, params: RequestParams = {}) =>
      this.request<QuoteByHash, any>({
        path: `/api/discounts/apply/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getFeesPrices = {
    /**
     * @description API endpoint to retrieve extra fees.
     *
     * @tags extra-fees
     * @name ExtraFeesGetFeesPricesRetrieve
     * @request GET:/api/extra-fees/get-fees-prices/
     * @secure
     * @response `200` `ExtraFees`
     */
    extraFeesGetFeesPricesRetrieve: (params: RequestParams = {}) =>
      this.request<ExtraFees, any>({
        path: `/api/extra-fees/get-fees-prices/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  countries = {
    /**
     * No description
     *
     * @tags options
     * @name OptionsCountriesRetrieve
     * @request GET:/api/options/countries/
     * @secure
     * @response `200` `Record<string,any>`
     */
    optionsCountriesRetrieve: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/options/countries/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  quote = {
    /**
     * @description API endpoints that allow orders to be viewed or edited.
     *
     * @tags orders
     * @name OrdersRetrieve
     * @request GET:/api/orders/{quote}/
     * @secure
     * @response `200` `Order`
     */
    ordersRetrieve: (quote: number, params: RequestParams = {}) =>
      this.request<Order, any>({
        path: `/api/orders/${quote}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow orders to be viewed or edited.
     *
     * @tags orders
     * @name OrdersUpdate
     * @request PUT:/api/orders/{quote}/
     * @secure
     * @response `200` `Order` Successfully updated the order.
     * @response `400` `void` Invalid input.
     * @response `404` `void` Order not found.
     */
    ordersUpdate: (quote: number, data: Order, params: RequestParams = {}) =>
      this.request<Order, void>({
        path: `/api/orders/${quote}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow orders to be viewed or edited.
     *
     * @tags orders
     * @name OrdersPartialUpdate
     * @request PATCH:/api/orders/{quote}/
     * @secure
     * @response `200` `Order`
     */
    ordersPartialUpdate: (quote: number, data: PatchedOrder, params: RequestParams = {}) =>
      this.request<Order, any>({
        path: `/api/orders/${quote}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  checkoutSession = {
    /**
     * No description
     *
     * @tags payments
     * @name PaymentsCheckoutSessionCreate
     * @request POST:/api/payments/checkout-session/{hash}/
     * @secure
     * @response `200` `CheckoutResponse`
     */
    paymentsCheckoutSessionCreate: (hash: string, params: RequestParams = {}) =>
      this.request<CheckoutResponse, any>({
        path: `/api/payments/checkout-session/${hash}/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  hash = {
    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashRetrieve
     * @request GET:/api/quotes-by-hash/{hash}/
     * @secure
     * @response `200` `QuoteByHash` Successfully retrieved quote.
     */
    quotesByHashRetrieve: (hash: string, params: RequestParams = {}) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashUpdate
     * @request PUT:/api/quotes-by-hash/{hash}/
     * @secure
     * @response `200` `QuoteByHash`
     */
    quotesByHashUpdate: (hash: string, data: QuoteByHash, params: RequestParams = {}) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashPartialUpdate
     * @request PATCH:/api/quotes-by-hash/{hash}/
     * @secure
     * @response `200` `QuoteByHash`
     */
    quotesByHashPartialUpdate: (hash: string, data: PatchedQuoteByHash, params: RequestParams = {}) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashAutofillCompanyCreate
     * @request POST:/api/quotes-by-hash/{hash}/autofill-company/
     * @secure
     * @response `200` `CofaceCompanyDataResponse`
     */
    quotesByHashAutofillCompanyCreate: (hash: string, data: CofaceCompanyResponse, params: RequestParams = {}) =>
      this.request<CofaceCompanyDataResponse, any>({
        path: `/api/quotes-by-hash/${hash}/autofill-company/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashCofaceTaskCreate
     * @request POST:/api/quotes-by-hash/{hash}/coface-task/
     * @secure
     * @response `200` `number`
     * @response `400` `Record<string,any>`
     */
    quotesByHashCofaceTaskCreate: (hash: string, params: RequestParams = {}) =>
      this.request<number, QuotesByHashCofaceTaskCreateError>({
        path: `/api/quotes-by-hash/${hash}/coface-task/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashConfirmCreate
     * @request POST:/api/quotes-by-hash/{hash}/confirm/
     * @secure
     * @response `200` `QuoteByHash`
     */
    quotesByHashConfirmCreate: (hash: string, params: RequestParams = {}) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/confirm/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashUpdateBillingCreate
     * @request POST:/api/quotes-by-hash/{hash}/update-billing/
     * @secure
     * @response `200` `QuoteByHash`
     */
    quotesByHashUpdateBillingCreate: (
      hash: string,
      data: QuotesByHashUpdateBillingCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/update-billing/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashUpdatePaymentCreate
     * @request POST:/api/quotes-by-hash/{hash}/update-payment/
     * @secure
     * @response `200` `QuoteByHash`
     */
    quotesByHashUpdatePaymentCreate: (hash: string, data: UpdatePayment, params: RequestParams = {}) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/update-payment/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashUpdateQuoteUserTypeCreate
     * @request POST:/api/quotes-by-hash/{hash}/update-quote-user-type/
     * @secure
     * @response `200` `QuoteByHash`
     */
    quotesByHashUpdateQuoteUserTypeCreate: (hash: string, data: UpdateUserBillingType, params: RequestParams = {}) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/update-quote-user-type/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Update unfilled fields in waypoints
     *
     * @tags quotes-by-hash
     * @name QuotesByHashUpdateQuoteWaypointsCreate
     * @request POST:/api/quotes-by-hash/{hash}/update-quote-waypoints/
     * @secure
     * @response `200` `QuoteByHash`
     */
    quotesByHashUpdateQuoteWaypointsCreate: (hash: string, data: UpdateQuoteWaypoints, params: RequestParams = {}) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/update-quote-waypoints/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashUpdateSelectedPriceOptionCreate
     * @request POST:/api/quotes-by-hash/{hash}/update-selected-price-option/
     * @secure
     * @response `200` `QuoteByHash`
     */
    quotesByHashUpdateSelectedPriceOptionCreate: (
      hash: string,
      data: UpdateSelectedPriceOption,
      params: RequestParams = {},
    ) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/update-selected-price-option/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashUpdateSpecialRequirementsCreate
     * @request POST:/api/quotes-by-hash/{hash}/update-special-requirements/
     * @secure
     * @response `200` `QuoteByHash`
     */
    quotesByHashUpdateSpecialRequirementsCreate: (
      hash: string,
      data: UpdateQuoteSpecialRequirements,
      params: RequestParams = {},
    ) =>
      this.request<QuoteByHash, any>({
        path: `/api/quotes-by-hash/${hash}/update-special-requirements/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow quotes to be viewed or edited.
     *
     * @tags quotes-by-hash
     * @name QuotesByHashWithOrderRetrieve
     * @request GET:/api/quotes-by-hash/{hash}/with-order/
     * @secure
     * @response `200` `QuoteWithOrder`
     */
    quotesByHashWithOrderRetrieve: (hash: string, params: RequestParams = {}) =>
      this.request<QuoteWithOrder, any>({
        path: `/api/quotes-by-hash/${hash}/with-order/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  uuid = {
    /**
     * @description Mixin that disables the list and destroy actions.
     *
     * @tags quotes-by-id
     * @name QuotesByIdUpdate
     * @request PUT:/api/quotes-by-id/{uuid}/
     * @secure
     * @response `200` `QuoteByID`
     */
    quotesByIdUpdate: (uuid: string, data: QuoteByID, params: RequestParams = {}) =>
      this.request<QuoteByID, any>({
        path: `/api/quotes-by-id/${uuid}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Mixin that disables the list and destroy actions.
     *
     * @tags quotes-by-id
     * @name QuotesByIdPartialUpdate
     * @request PATCH:/api/quotes-by-id/{uuid}/
     * @secure
     * @response `200` `QuoteByID`
     */
    quotesByIdPartialUpdate: (uuid: string, data: PatchedQuoteByID, params: RequestParams = {}) =>
      this.request<QuoteByID, any>({
        path: `/api/quotes-by-id/${uuid}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the default price for a given Quote
     *
     * @tags quotes-by-id
     * @name QuotesByIdGetDefaultPriceCreate
     * @request POST:/api/quotes-by-id/{uuid}/get-default-price/
     * @secure
     * @response `200` `DefaultPriceResponse`
     */
    quotesByIdGetDefaultPriceCreate: (uuid: string, data: DefaultPriceRequest, params: RequestParams = {}) =>
      this.request<DefaultPriceResponse, any>({
        path: `/api/quotes-by-id/${uuid}/get-default-price/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieves detailed information about a route, including the best route and transit time
     *
     * @tags quotes-by-id
     * @name QuotesByIdRouteDetailsCreate
     * @request POST:/api/quotes-by-id/{uuid}/route-details/
     * @secure
     * @response `200` `RouteDetailsResponse`
     */
    quotesByIdRouteDetailsCreate: (uuid: string, data: RouteDetailsRequest, params: RequestParams = {}) =>
      this.request<RouteDetailsResponse, any>({
        path: `/api/quotes-by-id/${uuid}/route-details/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Update quote's waypoint with provided data
     *
     * @tags quotes-by-id
     * @name QuotesByIdUpdateWaypointCreate
     * @request POST:/api/quotes-by-id/{uuid}/update-waypoint/
     * @secure
     * @response `200` `Waypoint`
     * @response `404` `Record<string,any>`
     */
    quotesByIdUpdateWaypointCreate: (uuid: string, data: Waypoint, params: RequestParams = {}) =>
      this.request<Waypoint, QuotesByIdUpdateWaypointCreateError>({
        path: `/api/quotes-by-id/${uuid}/update-waypoint/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  id = {
    /**
     * @description API endpoints that allow users to be viewed or edited.
     *
     * @tags users
     * @name UsersRetrieve
     * @request GET:/api/users/{id}/
     * @secure
     * @response `200` `User`
     */
    usersRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow users to be viewed or edited.
     *
     * @tags users
     * @name UsersUpdate
     * @request PUT:/api/users/{id}/
     * @secure
     * @response `200` `User`
     */
    usersUpdate: (id: number, data: User, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoints that allow users to be viewed or edited.
     *
     * @tags users
     * @name UsersPartialUpdate
     * @request PATCH:/api/users/{id}/
     * @secure
     * @response `200` `User`
     */
    usersPartialUpdate: (id: number, data: PatchedUser, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  me = {
    /**
     * @description API endpoints that allow users to be viewed or edited.
     *
     * @tags users
     * @name UsersMeRetrieve
     * @request GET:/api/users/me/
     * @secure
     * @response `200` `User`
     */
    usersMeRetrieve: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/me/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
